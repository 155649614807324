import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { SesionService } from '../../services/sesion.service';
import { EncryptService } from '../../common/services/encrypt/encrypt.service';
import { UserVO } from '../../common/vo/userVO';
import { notificationMessage } from './../../common/utils/configUrl';
import { LifeTimeSesionService } from 'src/app/services/life-time-session.service';

declare var $: any;

@Component({
    selector: 'app-login-cmp',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
    test: Date = new Date();
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;

    constructor(private element: ElementRef,
        private _data:DataService,
        private _life: LifeTimeSesionService,
        private _sesion: SesionService,
        private encryptService: EncryptService,
        private router: Router) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false
        let token = localStorage.getItem('token');
        if (token) {
          this.router.navigate(['dashboard']);
        }
    }

    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        body.classList.add('off-canvas-sidebar');
        const card = document.getElementsByClassName('card')[0];
        setTimeout(function() {
            // after 1000 ms we add the class animated to the login/register card
            card.classList.remove('card-hidden');
        }, 700);
    }
    sidebarToggle() {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        var sidebar = document.getElementsByClassName('navbar-collapse')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function() {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }
    // evento submit del formulario
    login(credenciales : UserVO){
    // llamada al servicio login para iniciar sesión
    this._sesion
    .login(credenciales.usuario, this.encryptService.encryptDataToBack(credenciales.password))
    .subscribe(

      
      // respuesta del servicio
      userLogged => {
        this._sesion.setUsuario(credenciales.usuario);
        // validacion si la respuesta obtubo datos y token
        if (userLogged.data && userLogged.data.token) {

          //envia true de que la pagina login se cargo por primera vez
          this._data.setRefreshPage(true);
          
          //Guardar token en local storage
          this._data.setToken(userLogged.data.token);
          
          //set info login
          this._data.setInfoLogin(userLogged.data.listaMenus);

          //inicializa la vida del token
          this._life.start(this._data.decodeToken());

          this.router.navigate(['dashboard']);
        } else if (userLogged.errors) {

          // If errors add messages
          this._data.addErrors(userLogged.errors);
        }
      },

      // problemas al conectar con el servicio
      err => {
        this._data.addError (
          notificationMessage.CONECTION_FAILED
        );
      }
    );
    }
    ngOnDestroy(){
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('login-page');
      body.classList.remove('off-canvas-sidebar');
    }
}
