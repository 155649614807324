import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html'
})
export class VehiculosComponent implements OnInit {

  public dataTable;

  constructor(private router: Router) { }

  ngOnInit() {
    this.dataTable = {
        dataRows : [
          {
          matricula : 'texto',
          marca : 'texto',
          modelo : 'texto',
          submarca : 'texto',
          color : 'texto',
          year : 'texto'
          },
          {
          matricula : 'texto',
          marca : 'texto',
          modelo : 'texto',
          submarca : 'texto',
          color : 'texto',
          year : 'texto'
          },
        ]
    };
  }

  detalle(){
    this.router.navigate(['/home-page/vehiculo']);
  }

}
