// general imports
import { Injectable } from '@angular/core';
import { endpoints, notificationMessage } from './../common/utils/configUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoVO } from '../common/vo/catalogoVO';
import { ErrorVO } from '../common/vo/errorVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { DataService } from './data.service';
import { LifeTimeSesionService } from './life-time-session.service';

/**
 * Service created by: irvin uriel
 */

//  @Injectable({
//    providedIn: 'root'
//  })
export class EmpresasService {
  private urlCreate: string = endpoints.TiposEmpresaCreate;
  private urlFindByAllFields: string = endpoints.TiposEmpresaFindByAllFields;
  private urlDelete: string = endpoints.TiposEmpresaDelete;
  private urlUpdate: string = endpoints.TiposEmpresaUpdate;
  constructor(private _http: HttpClient,
              private _dataService: DataService,
              private _life: LifeTimeSesionService,
              public _dialog: MatDialog) { }

  /**
   * Creación de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasCreate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<CatalogoVO>();
    requestVO.parameters = form;
    return this._http
      .post<ResponseVO<CatalogoResponseVO>>(this.urlCreate, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Creación de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasUpdate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<CatalogoVO>();
    requestVO.parameters = form;
    return this._http
      .put<ResponseVO<CatalogoResponseVO>>(this.urlUpdate, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }
  
  /**
   * Eliminacion de un tipo telefono
   * @param id : id de un dato de tipo telefono
   */
  tiposEmpresasDelete(id: number): Observable<ResponseVO<CatalogoVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    return this._http
      .delete<ResponseVO<CatalogoVO>>(`${this.urlDelete}/${id}`, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Busqueda por criterios de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasFindByAllFields(parameters: CatalogoVO, page: number, size: number, orderBy: string, orderType: string): Observable<ResponseVO<CatalogoResponseVO[]>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp =  {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<CatalogoVO>();
    requestVO.parameters = parameters;
    requestVO.page = page;
    requestVO.size = size;
    requestVO.orderBy = orderBy;
    requestVO.orderType = orderType;
    return this._http
      .post<ResponseVO<CatalogoResponseVO[]>>(this.urlFindByAllFields, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Funcion para confirmar la eliminacion del registro.
   * Invoca un Matdialog para realizar la confirmacion de eliminación.
   */
  eliminarTipoEmpresa(infoRow: CatalogoResponseVO): void {

    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: infoRow,
      disableClose: true
    });
    // Espera el cierre de la Modal, lo cual indicará que se aprobo la eliminacion.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.startLoading();
        // Se lanza el servicio delete TipoEmpresa
        this.tiposEmpresasDelete(result.id).subscribe(
          (response) => {
            // Se valida que la transaccion se haya realizado con exito y se notifica,
            // de lo contrario se muestran los errores.
            if (response.success) {
              this._dataService.setFetchData(true);
              this._dataService.setSuccessNotificationMessage(`${infoRow.idNombre} ${notificationMessage.DELETE}`);
            } else {
              this._dataService.addErrors(response.errors);
            }
            this._dataService.stopLoading();
          },
          (error: ErrorVO[]) => {
            this._dataService.stopLoading();
            this._dataService.addErrors(error);
          });
      }
    });

  }
}
