import { Component, OnInit } from '@angular/core';
import { Crud } from 'src/app/common/utils/validate-funcions.service';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalData } from 'src/app/common/vo/modalData';

@Component({
  selector: 'app-estandar',
  templateUrl: './estandar.component.html',
  styleUrls: ['./estandar.component.css']
})
export class EstandarComponent implements OnInit {

  public permisosTipo: Crud;
  public form: FormGroup;

  public idiomaList = [
    {value: 'es_MX', valueVisible: 'Español México'},
    {value: 'en_US', valueVisible: 'Ingles USA'}
  ];

  public idioma: string;

  public cardTitleCode = `
  <div class="card">
    <app-title-card [title]="'Titulo'" [icon]="'dashboard'"></app-title-card>
  </div>
    `;
  public btnSearchCode = `
  <btn-search></btn-search>
    `;
  public btnAdd = `
  <btn-create></btn-create>
  <table class="table table-striped table-no-bordered table-hover compact" cellspacing="0"
    width="100%" style="width:100%">
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Idioma</th>
        <th></th>
      </tr>
    </thead>
  </table>
    `;
  public label = `
<label class="lbl-border">
  texto no editable
</label>
    `;
  public btns = `
<div>
  <button mat-raised-button>Basic</button>
  <button mat-raised-button color="primary">Primary</button>
  <button mat-raised-button color="accent">Accent</button>
  <button mat-raised-button color="warn">Warn</button>
  <button mat-raised-button disabled>Disabled</button>
  <a mat-raised-button routerLink=".">Link</a>
</div>
    `;

  public cardCodeIcon = `
<i class="material-icons">accessibility_new</i>`;
  public modal = `
<p>
  modal example works!!
</p>
  `;
  public clickShowModal = `
  <button mat-raised-button (click)="showModal()">Show Modal</button>
  `;
  public module1 = `
  @NgModule({
    imports:      [
      ...
    ],
    declarations: [
        AppComponent,
        .
        .
        .`;
  public module2 = `
      ],
      bootstrap:    [ AppComponent ],
      entryComponents: [ `;
  public module3 = `
        ]

})`;

  public cardCodeTable1 = `
  <table class="table table-striped table-no-bordered table-hover compact">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Idioma</th>
            <th>
                <btn-create></btn-create>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>PRIVADO</td>
            <td>PRIVADO</td>
            <td>Español México</td>
            <td class="text-right">
                <!-- componente editar -->
                <app-button-edit [row]="row" [permisosFunciones]="permisosTipo"></app-button-edit>
                <!-- componente delete -->
                <app-button-delete [row]="row" [permisosFunciones]="permisosTipo"></app-button-delete>
            </td>
        </tr>
    </tbody>
</table>`;

public cardCodeTable2 = `
<table class="table table-striped table-no-bordered table-hover compact">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Idioma</th>
            <th>
                <btn-create></btn-create>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>PRIVADO</td>
            <td>PRIVADO</td>
            <td>Español México</td>
            <td class="text-right">
                <button mat-button [matMenuTriggerFor]="menu"><i class="material-icons">more_vert</i></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>edit</mat-icon>Item 1
                    </button>
                    <button mat-menu-item>
                        <mat-icon>close</mat-icon>Item 2
                    </button>
                    <button mat-menu-item>Item 3</button>
                    <button mat-menu-item>Item 4</button>
                </mat-menu>
            </td>
        </tr>
    </tbody>
</table>`;

public cardCodeTab = `
<div class="card">
<div class="card-header card-header-tabs card-header-primary">
  <div class="nav-tabs-navigation">
    <div class="nav-tabs-wrapper">
      <span class="nav-tabs-title">Tasks:</span>
      <ul class="nav nav-tabs" data-tabs="tabs">
        <li class="nav-item active">
          <a href="#profile" class="nav-link" data-toggle="tab">
            <i class="material-icons">bug_report</i> First
            <div class="ripple-container"></div>
          </a>
        </li>
        <li class="nav-item">
          <a href="#messages" class="nav-link" data-toggle="tab">
            <i class="material-icons">code</i> Second
            <div class="ripple-container"></div>
          </a>
        </li>
        <li class="nav-item">
          <a href="#settings" class="nav-link" data-toggle="tab">
            <i class="material-icons">cloud</i> Third
            <div class="ripple-container"></div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="card-body">
  <div class="tab-content">
    <div class="tab-pane active" id="profile">
      Aquí va tu contenido (Tab 1)
    </div>
    <div class="tab-pane" id="messages">
      Aquí va tu contenido (Tab 2)
    </div>
    <div class="tab-pane" id="settings">
      Aquí va tu contenido (Tab 3)
    </div>
  </div>
</div>
</div>`;

public cardCodeStepper = `
<div class="wizard-container">
<div class="card card-wizard" data-color="blue" id="wizardProfile">
  <div class="card-header text-center">
  </div>
  <div class="wizard-navigation">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link active" href="#titulo1" data-toggle="tab" role="tab">
          Titulo 1
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#titulo2" data-toggle="tab" role="tab">
          Titulo 2
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#titulo3" data-toggle="tab" role="tab">
          Titulo 3
        </a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div class="tab-pane active" id="titulo1">
      Contenido de titulo 1
    </div>
    <div class="tab-pane" id="titulo2">
      Contenido de titulo 2
    </div>
    <div class="tab-pane" id="titulo3">
      Contenido de titulo 3
    </div>
  </div>
</div>
</div>`;

public cardCodeHttp = `
// general imports
import { Injectable } from '@angular/core';
import { endpoints } from './../common/utils/configUrl';
import { Observable} from 'rxjs';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoVO } from '../common/vo/catalogoVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { GlobalService } from './global.service';

@Injectable({
 providedIn: 'root'
})

export class TelefonosService {
 private urlCreate: string = endpoints.TiposTelefonosCreate;
 private urlFindByAllFields: string = endpoints.TiposTelefonosFindByAllFields;
 private urlDelete: string = endpoints.TiposTelefonosDelete;
 private urlUpdate: string = endpoints.TiposTelefonosUpdate;
 //private _service:GlobalService;
 constructor(private _service:GlobalService) { }
 /**
  * Creación de un tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosCreate(form: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
   return this._service.post(form, this.urlCreate);
 }

 /**
  * Actualización tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosUpdate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
   return this._service.put(form, this.urlUpdate);
 }
  /**
  * Eliminacion de un tipo telefono
  * @param id : id de un dato de tipo telefono
  */
 tiposTelefonosDelete(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
   return this._service.post(parameters, this.urlDelete);
 }

 /**
  * Busqueda por criterios de un tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosFindByAllFields(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoResponseVO[]>> {
   return this._service.post(parameters, this.urlFindByAllFields);
 }
}`;

public cardCodeSelect = `
archivo.html
___________________________________________________________
<mat-form-field class="example-full-width" [floatLabel]="'never'">
  <mat-select placeholder="IDIOMAS">
    <mat-option *ngFor="let idiomas of idiomaList" [value]="idiomas.value">
      {{idiomas.valueVisible}}
    </mat-option>
  </mat-select>
</mat-form-field>


archivo.ts
___________________________________________________________

public idiomaList = [
  {value: 'es_MX', valueVisible: 'Español México'},
  {value: 'en_US', valueVisible: 'Ingles USA'}
];`;

public cardCodeInputTexarea = `
<mat-form-field>
<input matInput placeholder="Input">
</mat-form-field>

<mat-form-field>
<textarea matInput placeholder="Textarea"></textarea>
</mat-form-field>`;

public cardCodeDatepicker = `
<mat-form-field>
  <input matInput [matDatepicker]="picker" placeholder="Choose a date">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>`;

public cardCodeCheckbox = `
<mat-checkbox>Check me!</mat-checkbox>`;

public cardCodeRadioButton = `
<mat-radio-group aria-label="Select an option">
  <mat-radio-button value="1">Option 1</mat-radio-button>
  <mat-radio-button value="2">Option 2</mat-radio-button>
</mat-radio-group>`;

public cardCodeSlider = `
<mat-slider></mat-slider>`;

public cardCodeSliderToggle = `
<mat-slide-toggle>Slide me!</mat-slide-toggle>`;

  constructor(private _formBuilder: FormBuilder, private _data: DataService) {
    this.permisosTipo = { create: true, read: true, update: true, delete: true };
   }

  ngOnInit() {
    this.form = this._formBuilder.group({
      one: ['', Validators.required]
    });
  }

  showModal(){
    let modal = new ModalData();
    modal.component = ModalExample;
    modal.disableClose = false;
    this._data.setModalData(modal);
  }

}


@Component({
  selector: 'modal-example',
  template: `
<p>
modal example works!!
</p>
  `,
})
export class ModalExample {


  constructor() { }

}
