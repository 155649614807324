// general imports
import { Injectable } from '@angular/core';
import { endpoints } from './../common/utils/configUrl';
import { Observable} from 'rxjs';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoVO } from '../common/vo/catalogoVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { GlobalService } from './global.service';

//  @Injectable({
//    providedIn: 'root'
//  })

export class TelefonosService {
  private urlCreate: string = endpoints.TiposTelefonosCreate;
  private urlFindByAllFields: string = endpoints.TiposTelefonosFindByAllFields;
  private urlDelete: string = endpoints.TiposTelefonosDelete;
  private urlUpdate: string = endpoints.TiposTelefonosUpdate;
  //private _service:GlobalService;
  constructor(private _service:GlobalService) { }
  /**
   * Creación de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposTelefonosCreate(form: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
    return this._service.post(form, this.urlCreate);
  }

  /**
   * Actualizar tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposTelefonosUpdate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
    return this._service.put(form, this.urlUpdate);
  }
  
  /**
   * Eliminacion de un tipo telefono
   * @param id : id de un dato de tipo telefono
   */
  tiposTelefonosDelete(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
    return this._service.post(parameters, this.urlDelete);
  }

  /**
   * Busqueda por criterios de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposTelefonosFindByAllFields(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoResponseVO[]>> {
    return this._service.post(parameters, this.urlFindByAllFields);
  }
}
