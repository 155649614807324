import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { LockComponent } from './pages/lock/lock.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EstandarComponent, ModalExample } from './pages/estandar/estandar/estandar.component';
import { GlobalModule, TRANSLATE } from './modules/global.module';
import { ButtonSearchComponent } from './common/components/button-search/button-search.component';
import { MenuComponent } from './shared/menu/menu.component';
import { PricingComponent } from './pages/pricing/pricing.component';

@NgModule({
    imports:      [
        GlobalModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        NgxCaptchaModule,
        NgIdleKeepaliveModule.forRoot(),
        TRANSLATE
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        DashboardComponent,
        ModalExample,
        LoginComponent,
        RegisterComponent,
        LockComponent,
        MenuComponent,
        EstandarComponent,
        ButtonSearchComponent,
        PricingComponent
      ],
      bootstrap:    [ AppComponent ],
      entryComponents: [ 
        // DialogComponent,
        // DetalleTelefonosComponent,
        ModalExample,
        // DetalleEmpresasComponent,
        // DetalleTipoPersonaComponent,
        // DetalleTipoVehiculoComponent,
        // DetalleTipoSeccionComponent,
        // ConfirmationDialogComponent,
        // SessionDialogComponent,
        // DetalleTipoMascotaComponent,
        // DetalleNotificacionesComponent,
        // DetalleTipoRolComponent,
        // ParentescoComponent,
       ],
        schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class AppModule { }
