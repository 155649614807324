import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginGuard } from './common/guards/login.guard';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { LockComponent } from './pages/lock/lock.component';
import { RegisterComponent } from './pages/register/register.component';
import { EstandarComponent } from './pages/estandar/estandar/estandar.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        // canActivate: [LoginGuard]
      },
      {
        path: 'home-page',
        loadChildren: './pages/home-page/homePage.module#HomePageModule'
        // canActivate: [LoginGuard]
      },
      {
        path: 'registro',
        loadChildren: './modules/registro.module#RegistroModule'
        //canActivate: [LoginGuard]
      },
      {
        path: 'catalogos',
        loadChildren: './pages/catalogo/catalogo.module#CatalogoModule'
        //canActivate: [LoginGuard]
      }
    ]
  }, 
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'login',
      component: LoginComponent
    },
    {
      path: 'register',
      component: RegisterComponent
    },
    {
      path: 'lock',
      component: LockComponent
    }]
  },
  {
    path: 'estandar',
    component: EstandarComponent
  }, { path: '**', redirectTo: 'login' }
];
